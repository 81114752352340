/* You can add global styles to this file, and also import other style files */
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

.owl-theme .owl-nav :hover[class*=owl-],
.owl-theme .owl-nav [class*=owl-] {
    background: transparent;
    display: none;
}

.modal.show .modal-dialog {
    margin: auto; 
    transition: none;
  }
  .fade{
    transition: none;
  }

.cFont{
  font-family: "Jost", Serif,Sans-serif;
}